<template>
    <div>
        <div class="dkv">
            <div class="dd">
                <router-link to="/therapy">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/ter2.jpg" alt="">
                        <h3 class="title">Терапевтическое лечение</h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div>
                </router-link>
            </div>
            <div class="dd">
                <router-link to="/orthopedics">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/ort.jpg" alt="">
                        <h3 class="title">Ортопедическое лечение</h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div>
                </router-link>
            </div>
            <div class="dd">
                <router-link to="/surgery">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/hir2.jpg" alt="">
                        <h3 class="title">Хирургия</h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div>
                </router-link>
            </div>
            <div class="dd">
                <router-link to="/implantation">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/impl.jpg" alt="">
                        <h3 class="title">Имплантация зубов</h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="dks">
            <div class="dd">
                <router-link to="/kids">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/det.jpg" alt="">
                        <h3 class="title">Детская стоматология</h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div>
                </router-link>
            </div>
            <div class="dd">
                <router-link to="/orthodontics">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/orto.jpg" alt="">
                        <h3 class="title">Ортодонтия</h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div>
                </router-link>
            </div>
            <div class="dd">
                <router-link to="/lab">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/teh2.jpg" alt="">
                        <h3 class="title">Зуботехническая цифровая лаборатория</h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div>
                </router-link>
            </div>
            <div class="dd">
                <router-link to="/hygiene">
                    <div class="hover-effect-btn">
                        <img class="ikv" src="../assets/gig.jpg" alt="">
                        <h3 class="title">Профессиональная гигиена</h3>
                        <div class="overlay"></div>
                        <div class="button">Подробнее</div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Servis'
}
</script>

<style scoped>

.dkv {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    margin-top: 20px;
}

.dks {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    margin-top: 5px;
    margin-bottom: 120px;
}

.dd {
    margin-bottom: 25px;
}

.ikv {
    width: 280px;
    border-radius: 5px;
}

.hover-effect-btn {
  position: relative;
  width: 280px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0); /* Настройка цвета и прозрачности для блока с заголовком */
  transition: 0.5s ease;
  border-radius: 5px;
}
.hover-effect-btn:hover .overlay {
  display: block;
  background: rgba(0, 0, 0, 0.3); /* Настройка цвета и прозрачности для блока с заголовком при наведении */
}
.title {
  position: absolute;
  width: 100%;
  left: 0;
  top: 65%; /* Отступ сверху */
  font-size: 1.4em; /* Размер текста заголовка */
  text-align: center;
  text-transform: uppercase; /* Заглавные буквы */
  color: white; /* Цвет заголовка */
  z-index: 1;
  transition: top .5s ease;
  text-shadow: 1px 1px 2px black, 0 0 1em #0C435F, 0 0 0.2em #0C435F;
}
.hover-effect-btn:hover .title {
  /* top: 85%; Отступ сверху при наведении */
}
.button {
  position: absolute;
  width: 100%;
  left:0;
  top: 50%; /* Отступ сверху */
  text-align: center;
  opacity: 0;
  transition: opacity .35s ease;
  color: #fff;
  font-size: 17px;
}
.button a {
  padding: 15px 40px; /* Отступ */
  text-align: center;
  text-decoration: none;
  font-size: 15px;
  background: none; /* Цвет кнопки */
  color: white; /* Цвет текста */
  border: 1px solid white; /* Ширина, стиль и цвет границы */
  z-index: 1; 
}
.hover-effect-btn:hover .button {
  opacity: 1;
}

@media (max-width: 790px) {

    .ikv {
        width: 100%;
    }

    .hover-effect-btn {
        width: 100%;
    }

}
</style>