<template>
  <div>
    <Head />
    <div class="wrap">
      <h1>Кубенева Диана Николаевна</h1>
      
        <!-- <div><img class='ii' alt="" src="../assets/kdn1.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/kdn2.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/kdn3.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/kdn4.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/kdn5.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/kdn6.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/kdn7.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/kdn8.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/kdn9.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/kdn10.jpg" /></div> -->
     
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'BnuView',
  components: {
    Head,
    Foot,
  }
}
</script>

<style scoped>
.wrap {
  max-width: 90%;
  margin: 60px auto;
}

.ii {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}


@media (max-width: 790px) {

  

}

</style>

