<template>
    <div class="ns">
        <img alt="" src="../assets/vz.png">
        <h1>Данной страницы не существует.</h1>
        <router-link to="/"><button>Перейти на главную страницу</button></router-link>
    </div>
</template>

<script>
export default {
    name: 'NotFoundComponent'
}
</script>

<style scoped>
.ns {
    text-align: center;
}
img {
    width: 200px;
    margin-top: 100px;
}

button {
    margin-bottom: 200px;
}
</style>