<template>
  <div class="about">
    <Head />
    <div class="ab">
      <h1>Наши врачи</h1>
      <div class="nvra">
        <div class="row row-cols-1 row-cols-md-5 g-5">
          <div class="col">
            <router-link class="lin" to="/bnu">
              <div class="card h-100">
                <img src="../assets/bnu.jpg" class="card-img-top" alt="">
                <div class="card-body">
                  <h5 class="card-title">Быков Николай Юрьевич</h5>
                  <p class="card-text">
                      
                  </p>
                </div>
              </div>
            </router-link>
          </div>
          <!-- <div class="col">
            <router-link class="lin" to="/kdn">
              <div class="card h-100">
                <img src="../assets/kdn.jpg" class="card-img-top" alt="">
                <div class="card-body">
                  <h5 class="card-title">Кубенева Диана Николаевна</h5>
                  <p class="card-text">
                      
                  </p>
                </div>
              </div>
            </router-link>
          </div> -->
          <!-- <div class="col">
            <router-link class="lin" to="/mrm">
              <div class="card h-100">
                <img src="../assets/mrm.jpg" class="card-img-top" alt="">
                <div class="card-body">
                  <h5 class="card-title">Мурадян Рафаэл Мхитарович</h5>
                  <p class="card-text">
                      
                  </p>
                </div>
              </div>
            </router-link>
          </div> -->
          <div class="col">
            <router-link class="lin" to="/nem">
              <div class="card h-100">
                <img src="../assets/man.png" class="card-img-top" alt="">
                <div class="card-body">
                  <h5 class="card-title">Немешкин Сергей Александрович</h5>
                  <p class="card-text">
                      
                  </p>
                </div>
              </div>
            </router-link>
          </div>
          <div class="col">
            <!-- <router-link class="lin" to="/services"> -->
              <div class="card h-100">
                <img src="../assets/anna.jpg" class="card-img-top" alt="">
                <div class="card-body">
                  <h5 class="card-title">Анна</h5>
                  <p class="card-text">
                      
                  </p>
                </div>
              </div>
            <!-- </router-link> -->
          </div>
        </div>
      </div>
      <div class="car">
        <div>
          <h1>О клинике</h1>
          <!-- <h3>
            Добро пожаловать в стоматологическую клинику "Ева дент"!
          </h3> -->
          <p>
            Наша клиника рада предложить стоматологическую помощь всей Вашей семье! <br>
            У нас лицензия на все виды деятельности: хирургия, имплантация, лечение детей, удаление зубов, исправление прикуса 
            как в детском, так и во взрослом возрасте, все виды съемного и несъёмного протезирования (коронки, виниры). <br>
            Также мы располагаем собственной зуботехнической лабораторией, благодаря которой здесь же в клинике изготавливаем 
            зубные протезы и коронки в высоком качестве, в кратчайшие сроки. <br>
            Терапевтическое лечение зубов наши специалисты производят современными материалами качественно и без боли. <br>
            Отдельное внимание мы уделяем нашим маленьким пациентам, потому что считаем важным формирование зубного здоровья, 
            начиная с раннего возраста. <br>
            Мы ценим здоровье и время своих пациентов, поэтому консультируем, согласовываем, планируем лечение и 
            стараемся в совершенстве реализовать поставленные задачи. <br>
            В нашей клинике имеются все условия для комфортного лечения и пребывания пациентов. <br> 
            Администраторы клиники своевременно известят Вас и напомнят о запланированных визитах. <br>
            С нами здоровье Ваших зубов всегда будет на высоте, а улыбки просто блестящие!
          </p>
          <br><br>
        </div>
        <a-carousel autoplay arrows effect="fade">
          <template #prevArrow>
            <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
              <left-circle-outlined />
            </div>
          </template>
          <template #nextArrow>
            <div class="custom-slick-arrow" style="right: 10px">
              <right-circle-outlined />
            </div>
          </template>
          <div><img class='ii' alt="" src="../assets/clin.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/clin2.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/clin3.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/clin4.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/clin5.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/clin6.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/clin7.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/clin8.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/clin9.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/clin10.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/clin11.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/clin12.jpg" /></div>
          <div><img class='ii' alt="" src="../assets/clin13.jpg" /></div>
        </a-carousel>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import Foot from '@/components/Foot.vue'

export default {
  name: 'HomeView',
  components: {
    Head,
    Foot,
    ACarousel: Carousel,
    LeftCircleOutlined, 
    RightCircleOutlined,
  }
}
</script>

<style scoped>

.ab {
  max-width: 90%;
  margin: 60px auto;
  min-height: 500px;
}

.car {
  margin: 60px auto;
  margin-bottom: 140px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

.nvra {
  display: flex;
  justify-content: space-between;
}

.g-5, .gx-5 {
    --bs-gutter-x: 0rem;
}

.col {
  padding: 0 5px;
}

.card:hover {
  transform: scale(1.01);
  box-shadow: 2px 2px 10px 10px rgba(122, 122, 122, 0.7);
  transition: all 0.4s ease;
}

.card-title {
  text-align: center;
}

p {
  width: 95%;
}

.ii {
  width: 100%;
  height: 450px;
  overflow: hidden;
  object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 450px;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 35px;
  height: 25px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

@media (max-width: 790px) {

.car {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

}

</style>

