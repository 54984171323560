<template>
  <div>
    <Head />
    <div class="wrap">
      <h1>Документы</h1>
        <a href="https://cloud.mail.ru/public/GQK5/8y6LnyPDo"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Критерии
доступности и качества медицинской помощи, оказываемой в рамках Программы 
государственных гарантий бесплатного оказания гражданам в Чувашской Республике 
медицинской помощи на 2023 год и на плановый период 2024 и 2025 годов 
        </a> 
        <br><br>
        <a href="https://cloud.mail.ru/public/sdo8/tSL5m4m3Y"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          О возможности получения бесплатной медицинской помощи 
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/BPoy/KdRc7Jv7e"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          О порядке и условиях предоставления медицинской помощи 
в соответствии с программой государственных гарантий 
бесплатного оказания медицинской помощи  
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/feHv/N62FFEbTK"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Перечень
жизненно необходимых и важнейших лекарственных препаратов для медицинского применения
С изменениями и дополнениями от: 12 октября, 23 ноября 2020 г., 23 декабря 2021 г., 30 марта, 24 августа, 6 октября 2022 г.
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/wr3p/YVHBnb9tU"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Перечень
лекарственных препаратов, предназначенных для обеспечения лиц, больных гемофилией, муковисцидозом, гипофизарным нанизмом, 
болезнью Гоше, злокачественными новообразованиями лимфоидной, кроветворной и родственных им тканей, рассеянным склерозом, 
гемолитико-уремическим синдромом, юношеским артритом с системным началом, мукополисахаридозом I, II и VI типов, апластической 
анемией неуточненной, наследственным дефицитом факторов II (фибриногена), VII (лабильного), X (Стюарта - Прауэра), лиц после 
трансплантации органов и (или) тканей
С изменениями и дополнениями от: 23 ноября 2020 г., 23 декабря 2021 г.
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/sU41/TMJZEoSXm"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Перечень
лекарственных препаратов, отпускаемых населению в соответствии с перечнем групп 
населения и категорий заболеваний, при амбулаторном лечении которых лекарственные 
препараты и медицинские изделия отпускаются по рецептам врачей бесплатно, а также в 
соответствии с перечнем групп населения, при амбулаторном лечении которых 
лекарственные препараты отпускаются по рецептам врачей с 50-процентной скидкой 
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/vVun/EGAcS4Gxq"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Перечень
медицинских организаций, участвующих в 
реализации Программы государственных гарантий бесплатного оказания гражданам в Чувашской Республике 
медицинской помощи на 2023 год и на плановый период 2024 и 2025 годов, в том числе Территориальной программы обязательного 
медицинского страхования граждан в Чувашской Республике, и медицинских организаций, проводящих профилактические 
медицинские осмотры и диспансеризацию, в том числе углубленную диспансеризацию, в 2023 году 
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/Dzn2/fsbRpM7Ho"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Программа
государственных гарантий 
бесплатного оказания гражданам в 
Чувашской Республике 
медицинской помощи на 
2023 год и на плановый 
период 2024 и 2025 годов 
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/jvRk/uf5LYuic8"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Программа
государственных гарантий бесплатного оказания гражданам медицинской помощи на 2023 год и 
на плановый период 2024 и 2025 годов 
        </a>  
        <br><br>
        <router-link to="/lic">Лицензия</router-link>
        <br><br>
        <router-link to="/san">Санитарно-эпидемиологическое заключение</router-link>
        <br><br>
        <a href="https://cloud.mail.ru/public/VBhn/EgjkctD4X"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Информированное добровольное согласие пациента на медицинское вмешательство:
ПРОВЕДЕНИЕ КОНСУЛЬТАЦИИ И ОСМОТРА
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/fsKb/pBmxF5PmB"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Информированное добровольное согласие пациента на медицинское вмешательство:
ПРОВЕДЕНИЕ РЕНТГЕНОЛОГИЧЕСКОГО ОБСЛЕДОВАНИЯ
ЗУБОЧЕЛЮСТНОЙ СИСТЕМЫ
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/zkCy/VRHSRusRV"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Информированное добровольное согласие пациента на медицинское вмешательство:
ЛЕЧЕНИЕ ГЛУБОКОГО КАРИЕСА И ЭНДОДОНТИЧЕСКОЕ ЛЕЧЕНИЕ
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/y7Ty/QZPakJQFE"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Информированное добровольное согласие пациента на медицинское вмешательство:
ЛЕЧЕНИЕ КАРИЕСА ЗУБОВ (БЕЗ ЭНДОДОНТИЧЕСКОГО ЛЕЧЕНИЯ)
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/qkuv/gGaLPeHXj"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Информированное добровольное согласие пациента на медицинское вмешательство:
ЭНДОДОНТИЧЕСКОЕ ЛЕЧЕНИЕ КОРНЕВЫХ КАНАЛОВ ЗУБОВ
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/5F17/i9WbakY45"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Информированное добровольное согласие пациента на медицинское вмешательство:
ВОССТАНОВЛЕНИЕ ЗУБА (ЗУБОВ) МЕТОДОМ ПРЯМОЙ РЕСТАВРАЦИИ
        </a>
        <!-- <br><br>
        <a href="https://cloud.mail.ru/public/VLR1/8RWYHa754"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Информированное добровольное согласие пациента на медицинское вмешательство:
ВОССТАНОВЛЕНИЕ ЗУБА (ЗУБОВ) МЕТОДОМ ПРЯМОЙ РЕСТАВРАЦИИ
        </a> -->
        <br><br>
        <a href="https://cloud.mail.ru/public/BrD2/1MJ4Kfkhg"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Информированное добровольное согласие пациента на медицинское вмешательство:
ВОССТАНОВЛЕНИЕ ЗУБОВ МИКРОПРОТЕЗАМИ
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/1akw/diB4SYQRK"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Информированное добровольное согласие пациента на медицинское вмешательство:
СНЯТИЕ НЕСЪЕМНОЙ ОРТОПЕДИЧЕСКОЙ КОНСТРУКЦИИ
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/dKnd/vdA2TYMrh"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Информированное добровольное согласие пациента на медицинское вмешательство:
ПРОТЕЗИРОВАНИЕ СЪЕМНЫМИ ОРТОПЕДИЧЕСКИМИ КОНСТРУКЦИЯМИ
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/aXhU/GeSKuMbs5"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Информированное добровольное согласие пациента на медицинское вмешательство:
ПРОТЕЗИРОВАНИЕ НЕСЪЕМНЫМИ ПРОТЕЗАМИ С ОПОРОЙ НА ИМПЛАНТАТЫ
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/8shS/1ppie8dSn"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Информированное добровольное согласие пациента на медицинское вмешательство:
ВОССТАНОВЛЕНИЕ ЗУБНОГО РЯДА МЕТОДАМИ
All-on-4 («Все-на-4»), All-on-6 («Все-на-6»)
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/gZe9/PNjuLg9Tn"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Информированное добровольное согласие пациента НА ПРОВЕДЕНИЕ ПРОФЕССИОНАЛЬНОЙ ГИГИЕНЫ ПОЛОСТИ РТА И ЗУБОВ
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/hsar/13qLU4vdd"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Акт согласования ортопедических конструкций
        </a>        
        <br><br>
        <a href="https://cloud.mail.ru/public/yFWz/eUtGVVXLn"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Договор на оказание платных медицинских услуг
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/TfLG/TvFfxCMP1"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          ПАМЯТКА (РЕКОМЕНДАЦИИ) ПАЦИЕНТУ
ПОСЛЕ ПРОФЕССИОНАЛЬНОЙ ГИГИЕНЫ ПОЛОСТИ РТА И ЗУБОВ
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/poSb/FBG8uC8J2"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          ПАМЯТКА (РЕКОМЕНДАЦИИ) ПАЦИЕНТУ ПОСЛЕ СЪЕМНОГО ПРОТЕЗИРОВАНИЯ
        </a>
        <!-- <br><br>
        <a href="https://cloud.mail.ru/public/pWoT/iSxSVrw5h"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          ПАМЯТКА (РЕКОМЕНДАЦИИ) ПАЦИЕНТУ ПОСЛЕ СЪЕМНОГО ПРОТЕЗИРОВАНИЯ
        </a> -->
        <br><br>
        <a href="https://cloud.mail.ru/public/qw26/Ku6GLzuug"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          ПАМЯТКА (РЕКОМЕНДАЦИИ) ПАЦИЕНТУ ПОСЛЕ МЕСТНОЙ АНЕСТЕЗИИ
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/tfd1/yRUk7jABE"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Уведомление о последствиях
неявки на плановые приемы
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/RmaJ/jQwHVB3if"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Уведомление о несоблюдении рекомендаций лечащего врача,
нарушении режима лечения
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/sg3D/MXQRkHVKJ"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Отказ от медицинского вмешательства (продолжения стоматологического лечения)
        </a>
        <br><br>
        <a href="https://cloud.mail.ru/public/kU5d/MccQNS39o"            
          class="tr"
          target="_blank" 
          rel="noopener"
        >
          Адреса и телефоны органа исполнительной власти субъекта
Российской Федерации в сфере охраны здоровья граждан,
территориального органа Федеральной службы по надзору в сфере
здравоохранения и территориального органа Федеральной службы по надзору
в сфере защиты прав потребителей и благополучия человека
        </a>
        <br><br>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'PricesView',
  components: {
    Head,
    Foot,
  }
}
</script>

<style scoped>
.wrap {
  max-width: 90%;
  margin: 60px auto;
  min-height: 500px;
  overflow: hidden;
}

a {
  font-size: 1.2vw;
}

@media (max-width: 790px) {

  a {
  font-size: 17px;
}

}
</style>

