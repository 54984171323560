<template>
  <div>
    <Head />
    <div class="wrap">
      <div class="cont">
        <div>
          <h1>Терапия</h1>
          <div data-aos="zoom-in-down">
            <div class="row row-cols-1 row-cols-md-3 g-3">
              <div class="col">
                <router-link class="lin" to="/fillings">
                  <div class="card h-100">
                    <img src="../assets/filli.jpg" class="card-img-top" alt="">
                    <div class="card-body">
                      <h5 class="card-title">Зубные пломбы</h5>
                      <p class="card-text">
                          
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link class="lin" to="/restoration">
                  <div class="card h-100">
                    <img src="../assets/rest.jpg" class="card-img-top" alt="">
                    <div class="card-body">
                      <h5 class="card-title">Реставрация зубов</h5>
                      <p class="card-text">
                          
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link class="lin" to="/artrestoration">
                  <div class="card h-100">
                    <img src="../assets/artres.jpg" class="card-img-top" alt="">
                    <div class="card-body">
                      <h5 class="card-title">Художественная реставрация зубов</h5>
                      <p class="card-text">
                          
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import Foot from '@/components/Foot.vue'

export default {
  name: 'TherapyView',
  components: {
    Head,
    Foot,
    ACarousel: Carousel,
    LeftCircleOutlined, 
    RightCircleOutlined,
  }
}
</script>

<style scoped>

.wrap {
  max-width: 90%;
  margin: 60px auto;
  min-height: 500px;
}

.cont {
  margin: 60px auto;
  margin-bottom: 140px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

p {
  width: 95%;
}

.ii {
  width: 100%;
  height: 450px;
  overflow: hidden;
  object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 450px;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 35px;
  height: 25px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

@media (max-width: 790px) {

.cont {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

}

</style>

