<template>
  <div>
    <Head />
    <div class="wrap">
      <h1>Профессиональная гигиена</h1>
      <div class="cont">
        <div>
          <div data-aos="zoom-in-down">
            <div class="row row-cols-1 row-cols-md-2 g-2">
              <div class="col">
                <router-link class="lin" to="/ultrasound">
                  <div class="card h-100">
                    <img src="../assets/ultra.jpg" class="card-img-top" alt="">
                    <div class="card-body">
                      <h5 class="card-title">Профессиональная чистка зубов ультразвуком</h5>
                      <p class="card-text">
                          
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link class="lin" to="/airflow">
                  <div class="card h-100">
                    <img src="../assets/airf.jpg" class="card-img-top" alt="">
                    <div class="card-body">
                      <h5 class="card-title">Пескоструйная обработка AirFlow</h5>
                      <p class="card-text">
                          
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <h3>Гигиена полости рта</h3>
          <p>
            Зубные щетки не способны полностью удалить остатки пищи в ротовой полости. Из-за этого на зубах образуется налет, который постепенно превращается в прочный и некрасивый зубной камень. Он разрушает эмаль, приводит к воспалению и сползанию десен, пародонтиту и флюсу. Зубы становятся хрупкими и начинают крошиться.
          </p>
          <br>
          <p>
            Польза для взрослых:
          </p>
          <ul>
            <li>удаление органических отложений на зубах, камней;</li>
            <li>осветление эмали;</li>
            <li>предотвращение воспаления и кровоточивости десен;</li>
            <li>устранение чувствительности к горячему и холодному;</li>
            <li>снижение риска деминерализации эмали;</li>
            <li>предотвращение расшатывания зубов;</li>
            <li>ранняя диагностика и профилактика кариеса;</li>
            <li>удаление налета от курения, чая, кофе, вина.</li>
          </ul>
          <br>
          <p>
            Польза для детей:
          </p>
          <ul>
            <li>формирование здоровых зубов;</li>
            <li>профилактика стоматита и кариеса;</li>
            <li>контроль прорезывания молочных и коренных зубов;</li>
            <li>раннее выявление зубочелюстных аномалий;</li>
            <li>улучшение состояния десен;</li>
            <li>удаление отложений на зубах.</li>
          </ul>
          <br>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'HygieneView',
  components: {
    Head,
    Foot,
  }
}
</script>

<style scoped>

.wrap {
  max-width: 90%;
  margin: 60px auto;
  min-height: 500px;
}

.cont {
  margin: 0px auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

p {
  width: 95%;
}

.right {
  margin-left: 30px;
}

@media (max-width: 790px) {

  .right {
    margin-top: 50px;
    margin-left: 0px;
  }

  .cont {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }

  .col {
    margin-bottom: 10px;
  }

}

</style>

