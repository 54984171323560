<template>
  <div>
    <Head />
    <div class="wrap">
      <div class="cont">
        <div>
          <h1>Имплантация зубов</h1>
          <p>
            Имплантация зубов – это наиболее оптимальный и самый современный способ замещения недостающих элементов зубного ряда. Ведь в отличие от применения мостовидного протеза, при имплантации не требуется препарирования соседних здоровых зубов. 
          </p>
          <p>
            В то же время имплантация – это серьезная и достаточно сложная работа для стоматологов. Такая операция требует специализированного оборудования и высокой квалификации целой команды врачей – хирурга-имплантолога, ортопеда, терапевта и зубного техника.
          </p>
          <br>
          <p>
            Что такое зубной имплант?
          </p>
          <p>
            Это часть конструкции, которая помогает восстановить утраченный зуб.
          </p>
          <p>
            По сути имплант является искусственным корнем зуба. Он представляет собой винт, который вкручивается в кость челюсти — на место, где когда-то «сидел» родной зуб. Сверху к импланту прикручивается переходник: абатмент. А уже на нем — с помощью цемента или специального винта — фиксируется искусственная коронка. Это та часть зуба, которую мы видим во рту.
          </p>
          <p>
            По итогу пациент получает полноценный искусственный зуб, который выглядит и функционирует как настоящий.
          </p>
          <br>
          <p>
            Из чего делают импланты?
          </p>
          <p>
            Чаще — из сплавов титана. Титан не взаимодействует с тканями организма и подходит подавляющему числу пациентов.
          </p>
          <br>
          <p>
            Противопоказания к имплантации:
          </p>
          <ul>
            <li>онкологические заболевания на этапе активного лечения;</li>
            <li>болезни минерального обмена (из-за которых нарушаются процессы регенерации кости);</li>
            <li>проведение лучевой терапии на область головы и шеи в прошлом;</li>
            <li>болезни крови (лейкоцитозы, эритрозы, нарушение свертываемости крови);</li>
            <li>нелеченные инфекции в полости рта, пародонтит в стадии обострения;</li>
            <li>прием бисфосфонатов (препаратов для лечения остеопороза);</li>
            <li>острые инфекции, период обострения хронических заболеваний;</li>
            <li>беременность;</li>
            <li>возраст до 20-22 лет (импланты не устанавливают до тех пор, пока не завершится формирование лицевого скелета).</li>
          </ul>
          <br><br>
        </div>
          <a-carousel autoplay arrows effect="fade">
            <template #prevArrow>
              <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                <left-circle-outlined />
              </div>
            </template>
            <template #nextArrow>
              <div class="custom-slick-arrow" style="right: 10px">
                <right-circle-outlined />
              </div>
            </template>
            <div><img class='ii' alt="" src="../assets/pa1.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa2.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa3.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa4.jpg" /></div>
          </a-carousel>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import Foot from '@/components/Foot.vue'

export default {
  name: 'ImplantationView',
  components: {
    Head,
    Foot,
    ACarousel: Carousel,
    LeftCircleOutlined, 
    RightCircleOutlined,
  }
}
</script>

<style scoped>

.wrap {
  max-width: 90%;
  margin: 60px auto;
  min-height: 500px;
}

.cont {
  margin: 60px auto;
  margin-bottom: 140px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

p {
  width: 95%;
}

.ii {
  width: 100%;
  height: 450px;
  overflow: hidden;
  object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 450px;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 35px;
  height: 25px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

@media (max-width: 790px) {

.cont {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

}

</style>

