<template>
  <div>
    <Head />
    <div class="wrap">
      <div class="cont">
        <div>
          <h1>Ортопедия</h1>
          <div data-aos="zoom-in-down">
            <div class="row row-cols-1 row-cols-md-4 g-4">
              <div class="col">
                <router-link class="lin" to="/prosthesis">
                  <div class="card h-100">
                    <img src="../assets/prot4.jpg" class="card-img-top" alt="">
                    <div class="card-body">
                      <h5 class="card-title">Съемное протезирование</h5>
                      <p class="card-text">
                          
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link class="lin" to="/zirconium">
                  <div class="card h-100">
                    <img src="../assets/zirk.jpg" class="card-img-top" alt="">
                    <div class="card-body">
                      <h5 class="card-title">Безметалловые циркониевые коронки</h5>
                      <p class="card-text">
                          
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link class="lin" to="/cermet">
                  <div class="card h-100">
                    <img src="../assets/cerm2.jpg" class="card-img-top" alt="">
                    <div class="card-body">
                      <h5 class="card-title">Металлокерамические коронки</h5>
                      <p class="card-text">
                          
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
              <div class="col">
                <router-link class="lin" to="/veneers">
                  <div class="card h-100">
                    <img src="../assets/ort.jpg" class="card-img-top" alt="">
                    <div class="card-body">
                      <h5 class="card-title">Виниры</h5>
                      <p class="card-text">
                          
                      </p>
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'OrthopedicsView',
  components: {
    Head,
    Foot,
  }
}
</script>

<style scoped>

.wrap {
  max-width: 90%;
  margin: 60px auto;
  min-height: 500px;
}

.cont {
  margin: 60px auto;
  margin-bottom: 140px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

p {
  width: 95%;
}



@media (max-width: 790px) {

.cont {
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}

}

</style>

