<template>
  <div class="home">
    <Head />
    <Stik />
    <div class="mh"></div>
    <div class="bb">
      <div class="inbb">
        <h2>Семейная стоматология</h2>
        <h3>Мы ценим своих пациентов</h3>
        <!-- <img class="dn" alt="" src="../assets/za.png"> -->
      </div>
    </div>
    <div class="zz">
      <div class="inzz">
        <div class="hi"></div>
        <div>
          <h1>Добро пожаловать в EVAdent!</h1>
          <h5>
            Наш девиз - обеспечивать совершенство и создавать здоровые улыбки с помощью самых современных технологий и высокого мастерства.
          </h5>
        </div>
        <div data-aos="zoom-in-down">
          <div class="row row-cols-1 row-cols-md-3 g-3">
            <div class="col">
              <router-link class="lin" to="/services">
                <div class="card h-100">
                  <img src="../assets/us2.jpg" class="card-img-top" alt="">
                  <div class="card-body">
                    <h5 class="card-title">Лечение</h5>
                    <p class="card-text">
                        
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col">
              <router-link class="lin" to="/about">
                <div class="card h-100">
                  <img src="../assets/vr.jpg" class="card-img-top" alt="">
                  <div class="card-body">
                    <h5 class="card-title">Наши врачи</h5>
                    <p class="card-text">
                        
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
            <div class="col">
              <router-link class="lin" to="/lab">
                <div class="card h-100">
                  <img src="../assets/teh2.jpg" class="card-img-top" alt="">
                  <div class="card-body">
                    <h5 class="card-title">Технологии</h5>
                    <p class="card-text">
                        
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="hi"></div>
        <div>
          <h1>Косметические стоматологические процедуры</h1>
          <h5>
            Ваша улыбка имеет значение! Позвольте нам в EVAdent улучшить внешний вид вашей улыбки с помощью «полного преобразования», выбрав одну или несколько наших процедур косметической стоматологии.
          </h5>
        </div>
        <Card />
      </div>
    </div>
    <div class="con">
      <div class="coce">
        <div class="incon">
          <font class="pod">
            Подарите себе повод улыбнуться!
          </font>
          <font class="zap2">
            Запишитесь на прием сегодня
          </font>
        </div>
        <div class="cbt">
          <button 
            class="btsv"
            type="button" 
            data-bs-toggle="modal" 
            data-bs-target="#exampleModal"
          >
            Свяжитесь с нами
          </button>
        </div>
      </div>
    </div>
    <div class="vop">
      <h1 class="cvo">Часто задаваемые вопросы</h1>
      <div class="invop">
        <div class="spv">
          <Akkor />
        </div>
        <div class="imv">
          <!-- <img class="vopr" alt="" src="../assets/vopr.jpg"> -->
        </div>
      </div>
    </div>
    <div class="otz">
      <div class="dot"></div>
      <h1 class="cot">Что говорят наши пациенты</h1>
      <div class="inot">
        <div class="inotz">
          <div class="yaot" style="overflow:hidden;position:relative;"><iframe style="width:100%;height:100%;border:1px solid #e6e6e6;border-radius:8px;box-sizing:border-box" src="https://yandex.ru/maps-reviews-widget/170202452251?comments"></iframe><a href="https://yandex.ru/maps/org/yeva_dent/170202452251/" target="_blank" style="box-sizing:border-box;text-decoration:none;color:#b3b3b3;font-size:10px;font-family:YS Text,sans-serif;padding:0 20px;position:absolute;bottom:8px;width:100%;text-align:center;left:0;overflow:hidden;text-overflow:ellipsis;display:block;max-height:14px;white-space:nowrap;padding:0 16px;box-sizing:border-box">Ева-дент на карте Чувашской Республики — Яндекс Карты</a></div>
        </div>
      </div>
    </div>
    <Modal />
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'
import Card from '@/components/Card.vue'
import Akkor from '@/components/Akkor.vue'
import Stik from '@/components/Stik.vue'

export default {
  name: 'HomeView',
  components: {
    Head,
    Foot,
    Card,
    Akkor,
    Stik,
  }
}
</script>

<style scoped>

.mh {
  height: 53px;
  transition: all 0.4s ease;
}

.bb {
  width: 100%;
  height: 100vh;
  background-image: url(../assets/clin.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
}

.inbb {
  margin-left: 30px;
}

h2 {
  color: #fff;
  font-family: 'Lato',Helvetica,Arial,Lucida,sans-serif!important;
  font-size: 56px;
  font-weight: bold;
  padding: 20px;
  text-shadow: 1px 1px 2px black, 0 0 1em gray, 0 0 0.2em grey;
  margin-top: 30vh;
}

h3 {
  color: #fff;
  font-weight: bold;
  padding: 30px;
  text-shadow: 1px 1px 2px black, 0 0 1em gray, 0 0 0.2em grey;
}

.dn {
  padding: 30px;
}

.dn:hover {
  animation: bounce 2s ease infinite;
}
@keyframes bounce {
  70% { transform:translateY(0%); }
  80% { transform:translateY(-15%); }
  90% { transform:translateY(0%); }
  95% { transform:translateY(-7%); }
  97% { transform:translateY(0%); }
  99% { transform:translateY(-3%); }
  100% { transform:translateY(0); }
}

.zz {
  height: 1200px;
  background-image: url(../assets/za.png);
  background-repeat: no-repeat;
  place-content: center;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.inzz {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.hi {
  height: 40px;
}

h1 {
  font-weight: bold;
  color: #0C435F;
}

h5 {
  font-weight: bold;
  color: #686868;
  margin-top: 20px;
}

.row {
  margin-top: 20px;
}

.card-img-top {
  padding: 0;
}

.card-title {
  font-size: 24px;
  color: #0C435F;
  text-transform: uppercase;
  margin-top: 10px;
}

.card {
  transition: all 0.4s ease;
}

.card:hover {
  transform: scale(1.01);
  box-shadow: 2px 2px 10px 10px rgba(122, 122, 122, 0.7);
  transition: all 0.4s ease;
}

.card-body {
  background: transparent;
}

.lin {
  text-decoration: none;
}

.con {
  display: flex;
  align-items: center;
  height: 250px;
  background-color: #0C435F;
}

.coce {
  max-width: 1400px;
  margin: auto;
  display: flex;
}

.incon {
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.pod {
  color: #f9df81;
  font-size: 33px;
  font-weight: bold;
  text-align: center;
  line-height: 42px;
}

.zap2 {
  color: #fff;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}

.cbt {
  display: flex;
  align-items: center;
}

.btsv {
  width: 220px;
  height: 50px;
  background-color: #fff;
  border: #fff;
  color: #0C435F;
  font-size: 21px;
  transition: all 0.4s ease;
}

.btsv:hover {
  background-color: #f9df81;
  border: #f9df81;
  transition: all 0.4s ease;
}

.vop {
  height: 550px;
  text-align: center;
}

.cvo {
  margin-top: 60px;
}

.invop {
  margin-top: 30px;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr)); */
}

.spv {
  padding: 30px;
}

.imv {
  padding: 30px;
}

.vopr {
  max-width: 500px;
}

.otz {
  height: 800px;
  background-image: url(../assets/za.png);
  place-content: center;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  text-align: center;
}

.dot {
  height: 60px;  
}

.cot {
  color: #0C435F;
}

.inot {
  display: flex;
  justify-content: center;
}

.inotz {
  background-color: #fff;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.yaot {
  width:690px;
  height:500px;
}

@media (max-width: 790px) {

  .bb {
    width: 100%;
    height: 200px;
    
  }

  .inbb {
    margin-left: 0px;
  }

  h2 {
    font-size: 25px;
    margin-top: 15px;
    padding: 10px;
    width: 150px;
  }

  h3 {
    font-size: 19px;
    padding: 10px;
    width: 200px;
  }

  .dn {
    display: none;
  }

  h5 {
    font-size: 17px;
    width: 300px;
    margin: auto;
  }

  .row {
    margin-left: 0;
    margin-right: 0;
  }

  .con {
    margin-top: 1600px;
    height: 350px;
  }

  .pod {
    margin-top: 40px;
  }

  .coce {
    flex-direction: column;
  }

  .cbt {
    justify-content: center;
  }

  .btsv {
    width: 90%;
    margin: 60px auto;
  }

  .vop {
    margin-top: 0;
    height: 480px;
  }

  .invop {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .spv {
    padding: 0;
  }

  .imv {
    padding: 0;
    width: 100%;
    margin-top: 10px;
  }

  .vopr {
    width: 100%;
    padding: 0;
  }

  .otz {
    margin-top: 30px;
  }

  .inotz {
    margin-top: 30px;
  }

  .yaot {
    width:300px;
    height:530px;
  }

}

</style>
