<template>
  <div>
    <Head />
    <div class="wrap">
      <div class="cont">
        <div>
          <h1>Детская стоматология</h1>
          <p>
            Первый визит ребенка к детскому врачу-стоматологу всегда очень волнительный и эмоциональный, поэтому успех последующего лечения зубов зависит и от родителей и от врача в равной степени. Важно правильно подготовить ребенка к визиту в стоматологическую клинику. Хорошее впечатление от первого посещения зубного врача может сформировать отношение ребенка к культуре здоровья зубов на долгие годы.
          </p>
          <br>
          <p>
            Рекомендации для родителей.
          </p>
          <p>
            Кариес и другие заболевания зубов развиваются у детей очень быстро, поэтому очень важно ранее обращение к стоматологу. Не дожидаясь острой боли настоятельно рекомендуем Вам с детьми регулярно обращаться к нам на профилактический осмотр, начиная с появления первых молочных зубов. 
          </p>
          <p>
            Для подготовки детей к визиту к стоматологу расскажите им о своем опыте лечения зубов. Покажите им свои зубы. Приучайте детей к ежедневной чистке, формируйте культуру ухода за своими зубами. 
          </p>
          <br>
          <p>
            Детям необходимо сохранять налаженный контакт с докторами.
          </p>
          <p>
            В том случае, когда дело дошло до боли, убедите ребенка в том, что зубной врач поможет — боль станет меньше, а потом и совсем пройдет, только нужно потерпеть. Не ругайте и не пугайте ребенка страшыми рассказами и примерами, постарайтесь максимально успокоить его и внушить доверие к врачу. Разговоры о профилактике зубной боли, в таком случае, оставьте на потом, когда зубная боль будет устранена. Будьте готовы несколько раз повторить то, что Вы уже говорили ребенку, не раздражайтесь и держите себя уверенно. Помните, что Ваши эмоции отражаются на ребенке. Очень важно преодолеть страх, поэтому, в зависимости от возраста и эмоционального состояния ребенка, Вы можете присутствовать при проведении лечения. 
          </p>
          <p>
            Ребенку важна Ваша поддержка.
          </p>
          <br>
          <p>
            С возрастом у детей своевременное посещение стоматолога должно стать привычным делом. Мы рекомендуем развивать у них самоконтроль и ответственность за сохранение собственных зубов.
          </p>
          <p>
            Не забывайте напоминать о важности своевременного ухода за зубами.
          </p>
          <br><br>
        </div>
          <a-carousel autoplay arrows effect="fade">
            <template #prevArrow>
              <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                <left-circle-outlined />
              </div>
            </template>
            <template #nextArrow>
              <div class="custom-slick-arrow" style="right: 10px">
                <right-circle-outlined />
              </div>
            </template>
            <div><img class='ii' alt="" src="../assets/pa1.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa2.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa3.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa4.jpg" /></div>
          </a-carousel>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import Foot from '@/components/Foot.vue'

export default {
  name: 'KidsView',
  components: {
    Head,
    Foot,
    ACarousel: Carousel,
    LeftCircleOutlined, 
    RightCircleOutlined,
  }
}
</script>

<style scoped>

.wrap {
  max-width: 90%;
  margin: 60px auto;
  min-height: 500px;
}

.cont {
  margin: 60px auto;
  margin-bottom: 140px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

p {
  width: 95%;
}

.ii {
  width: 100%;
  height: 450px;
  overflow: hidden;
  object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 450px;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 35px;
  height: 25px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

@media (max-width: 790px) {

.cont {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

}

</style>

