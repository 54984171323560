<template>
    <div class="ff">
        <div class="round">
            <br><br>
            <h5>
                Импланты <br>
                за 20.000 р
            </h5>            
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.ff {
    display: flex;
    justify-content: flex-end;
}

.round {
    position: fixed;
    margin-top: 30vh;
    margin-right: 5vw;
    z-index: 10;
    opacity: 0.7;
    width: 10em;
    height: 10em;
    border: 5px solid red;
    border-radius: 50%;
    align-items: center;
    text-align: center;  
    background: mistyrose;
}

@media (max-width: 790px) {

    .round {
        margin-top: 12vh;
        margin-right: 2vw;
    }

}

</style>