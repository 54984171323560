<template>
  <div class="serv">
    <Head />
    <div class="usl">
      <h1>Лечение</h1>
      <Servis />
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Servis from '@/components/Servis.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'ServicesView',
  components: {
    Head,
    Servis,
    Foot,
  }
}
</script>

<style scoped>

.usl {
  max-width: 90%;
  margin: 60px auto;
}

</style>

