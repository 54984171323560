<template>
  <div>
    <Head />
      <div class="wrap">
        <h1>Контакты</h1>
        <h4>Чебоксарский район, пос. Лапсары, д. Чергаши, ул. Пригородная, 30</h4>
        <div class="tel">
          <a href="tel:+79674700044">
            <h4 class="ht">
              +7 (967) 470-00-44
            </h4>
          </a> 
          <a href="tel:+79674700044">
            <h4 class="ht">
              +7 (8352) 36-00-44
            </h4>
          </a>  
        </div>
        <h4>e-mail: <a class="em" href="mailto:zubtehlab21@mail.ru">zubtehlab21@mail.ru</a></h4>
        <br>
        <h5>
          Режим работы: <br><br>
          ПН-ПТ 9:00 - 19:00, <br>
          СБ-ВС выходной
        </h5>
        <br>
      </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'ContactsView',
  components: {
    Head,
    Foot,
  }
}
</script>

<style scoped>
.wrap {
  max-width: 90%;
  margin: 60px auto;
  min-height: 310px;
}

.tel {
  width: 250px;
}

.ht {
  width: 250px;
  transition: all 0.4s ease;
}

.ht:hover {
  color: #2EA3F2;
  transition: all 0.4s ease;
}
</style>