<template>
    <div class="hh">
        <div 
            class="wi"
            v-if="!small"
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
            </svg>
            &nbsp; &nbsp; 
            <a class="tel" href="tel:+79674700044">
                <h5 class="ht">
                    36-00-44
                </h5>
            </a>            
            &nbsp; &nbsp; &nbsp; &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
            </svg>
            &nbsp; &nbsp; 
            <router-link 
                class="rl"
                to="/contacts">
                <h5 class="ha">
                    пос. Лапсары, д. Чергаши, ул. Пригородная, 30 
                </h5>
            </router-link>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-pencil-square" viewBox="0 0 16 16">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg>
            &nbsp; &nbsp; 
            <button 
                type="button" 
                data-bs-toggle="modal" 
                data-bs-target="#exampleModal"
            >
                <h5 class="hz">
                    Записаться на прием
                </h5>
            </button>
        </div>
    </div>
    <Modal />
    <Hbody />
</template>

<script>
import Modal from '@/components/Modal.vue'
import Hbody from '@/components/Hbody.vue'

export default {
    name: 'Head',
    components: {
        Modal,
        Hbody,
    },
    data: () => ({
        small: false
    }),
    created() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        onResize() {
        this.small = window.innerWidth <= 790;
        }
    },
}
</script>

<style scoped>
.hh {
    height: 49px;
    background-color: #0C435F;
    position: sticky;
    top: 0;
    z-index: 20;
}

.wi {
    height: 49px;
    display: flex;
    justify-content: end;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
}

.tel {
    text-decoration: none;
}

h5 {
    color: #fff;
    font-size: 14px;
    transition: all 0.4s ease-in-out;
    margin-bottom: 0;
}

h5:hover {
    color: rgb(186 196 207);
    transition: all 0.4s ease-in-out;
}

.rl {
    text-decoration: none;
}

button {
    background: none;
    border: none;
}

@media (max-width: 790px) {

    .hh {
        display: none;
    }

}

</style>