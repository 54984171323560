<template>
  <div>
    <Head />
    <div class="wrap">
      <h1>Мурадян Рафаэл Мхитарович</h1>
      
        <!-- <div><img class='ii' alt="" src="../assets/mrm1.png" /></div>
        <div><img class='ii' alt="" src="../assets/mrm2.png" /></div>
        <div><img class='ii' alt="" src="../assets/mrm3.png" /></div>
        <div><img class='ii' alt="" src="../assets/mrm4.png" /></div>
        <div><img class='ii' alt="" src="../assets/mrm5.png" /></div>
        <div><img class='ii' alt="" src="../assets/mrm6.png" /></div>
        <div><img class='ii' alt="" src="../assets/mrm7.png" /></div>
        <div><img class='ii' alt="" src="../assets/mrm8.png" /></div>
        <div><img class='ii' alt="" src="../assets/mrm9.png" /></div> -->
     
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'BnuView',
  components: {
    Head,
    Foot,
  }
}
</script>

<style scoped>
.wrap {
  max-width: 90%;
  margin: 60px auto;
}

.ii {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}


@media (max-width: 790px) {

  

}

</style>

