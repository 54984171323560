<template>
    <div class="ff">
        <div class="fh"></div>
        <div class="hl" style="position:relative;overflow:hidden;"><a href="https://yandex.ru/maps/org/yeva_dent/170202452251/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Ева-дент</a><a href="https://yandex.ru/maps/11156/chuvash-republic/category/dental_clinics/184106132/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:14px;">Стоматологическая клиника в Чувашской Республике</a><iframe src="https://yandex.ru/map-widget/v1/?ll=47.211220%2C56.067716&mode=search&oid=170202452251&ol=biz&utm_source=main_stripe_big&z=16.37" width="1050" height="460" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div>
        <div class="re">
            <h6>ПН-ПТ 9:00 - 19:00, СБ-ВС выходной</h6>
        </div>
        <div class="unre">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
            </svg>
            &nbsp; &nbsp; 
            <a class="tel" href="tel:+79674700044">
                <h5>
                    36-00-44
                </h5>
            </a>  
        </div>
        <br>
        <br>
    </div>
</template>

<script>
export default {
    name: 'Foot'
}
</script>

<style scoped>
.ff {
    min-height: 560px;
    background-color: #0C435F!important;
}

.fh {
    height: 280px;
    background-image: linear-gradient(180deg,#f9df81 30%,#0c435f 100%),url(../assets/za.png);
}

.hl {
    width: 75%;
    /* background-color: white;     */
    position: relative;
    margin: -300px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 20px 0px rgba(182, 181, 181, 0.7);
}

.re {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 350px;
}

.unre {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

h5 {
    color: #fff;
}

h6 {
    color: #fff;
}

@media (max-width: 790px) {

    .hl {
        width: 95%;
    }

    iframe {
        width: 100%;
        height: 600px;
    }

}
</style>