<template>
  <div>
    <Head />
    <div class="wrap">
      <div class="cont">
        <div class="left">
          <h1>Профессиональная чистка зубов ультразвуком</h1>
          <p>
            Современная чистка зубов ультразвуком – это самый эффективный и безопасный метод очищения ротовой полости от каменных отложений.
          </p>
          <br>
          <p>
            Твердый камень тяжело поддается удалению механическими способами, а удаление налетов химическими веществами приводит к повреждению эмали. Этих всех недостатков нет у ультразвука.
          </p>
          <p>
            С данной процедуры большинство стоматологов начинают любое лечение, что значительно улучшает отдаленные результаты (пломбы держаться крепче и пр.) и сокращает необходимость визитов к стоматологу.
          </p>
          <br>
          <p>
            Формированию твердых отложений ряд факторов, ускоряющих процесс:
          </p>
          <ul>
            <li>нерегулярная гигиена;</li>
            <li>неправильная гигиена. Чистка зубов горизонтальными движениями не затрагивает труднодоступные места. Очищать межзубные промежутки следует перемещая щетку вертикально;</li>
            <li>рацион питания. Большое количество сладкого и мучного приводит к налипанию углеводов на эмаль и размножению патогенной микрофлоры;</li>
            <li>физиологические особенности - неправильный прикус, скученность зубов;</li>
            <li>нарушения обмена веществ;</li>
            <li>курение. Сигаретные смолы оседают на зубах, провоцируя образование пленки и бляшек.</li>
          </ul>
          <br>
          <p>
            Польза профессиональной чистки зубов в клинике: 
          </p>
          <ul>
            <li>выявляет кариес. Разрушение эмали и дентина может быть скрыто под слоем зубного камня;</li>
            <li>предотвращает образование нового кариеса, в том числе у тех, кто носит брекеты;</li>
            <li>предупреждает воспаления десен, пародонтит, оголение корней, кровоточивость, неприятный запах изо рта;</li>
            <li>подготавливает ротовую полость к операции, установке импланта;</li>
            <li>осветляет зубы без агрессивного химического отбеливания.</li>
          </ul>
          <br>
          <p>
            Противопоказания к ультразвуковой чистке зубов:
          </p>
          <ul>
            <li>ношение имплантатов;</li>
            <li>наличие различных ортопедических конструкций в ротовой полости;</li>
            <li>нарушение сердечного ритма (аритмия);</li>
            <li>некоторые хронические заболевания внутренних органов (бронхиальная астма, бронхит, эндокардит и др.);</li>
            <li>ОРВИ;</li>
            <li>острые инфекционные заболевания;</li>
            <li>дети и подростки;</li>
            <li>нельзя делать лицам, которые страдают ВИЧ, гепатитом, туберкулезом.</li>
          </ul>
          <br>
          <p>
            Более подробную консультацию Вы можете получить, обратившись к нам. 
          </p>
          <br><br>
        </div>
          <a-carousel autoplay arrows effect="fade">
            <template #prevArrow>
              <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                <left-circle-outlined />
              </div>
            </template>
            <template #nextArrow>
              <div class="custom-slick-arrow" style="right: 10px">
                <right-circle-outlined />
              </div>
            </template>
            <div><img class='ii' alt="" src="../assets/pa1.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa2.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa3.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa4.jpg" /></div>
          </a-carousel>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import Foot from '@/components/Foot.vue'

export default {
  name: 'OrthopedicsView',
  components: {
    Head,
    Foot,
    ACarousel: Carousel,
    LeftCircleOutlined, 
    RightCircleOutlined,
  }
}
</script>

<style scoped>

.wrap {
  max-width: 90%;
  margin: 60px auto;
  min-height: 500px;
}

.cont {
  margin: 60px auto;
  margin-bottom: 140px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

.left {
  margin-right: 30px;
}

p {
  width: 95%;
}

.ii {
  width: 100%;
  height: 450px;
  overflow: hidden;
  object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 450px;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 35px;
  height: 25px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

@media (max-width: 790px) {

.cont {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

}

</style>

