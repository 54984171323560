<template>
  <div>
    <Head />
    <div class="wrap">
      <div class="cont">
        <div class="left">
          <h1>Удаление зубов</h1>
          <p>
            Удаление зуба – это хирургическая операция, в ходе которой путем проведения приемов механического воздействия на зубоальвеолярный сегмент с помощью специальных инструментов производятся расслоение, рассечение, растяжение и разрыв мягкотканых структур, в результате чего осуществляется извлечение зуба из альвеолы.
          </p>
          <br>
          <p>
            Показания к удалению зуба:
          </p>
          <ul>
            <li>сильное разрушение зуба кариесом, травмах и невозможности восстановления;</li>
            <li>сильное разрушение связочного аппарата, удерживающего зуб при болезнях;</li>
            <li>периодонтит, когда ликвидировать очаг воспаления не предоставляется возможным;</li>
            <li>неправильное расположение зубов;</li>
            <li>подготовка к ортодонтическому лечению;</li>
            <li>препятствие фиксации протеза;</li>
            <li>сверхкомплектные зубы.</li>
          </ul>
          <br>
          <p>
            Что важно после удаление зуба:
          </p>
          <ul>
            <li>принимать пищу не ранее 2-3 часов, жевать другой стороной - противоположной удаленному зубу;</li>
            <li>нельзя усиленно полоскать рот в этот день, принимать ванну или ходить в баню, заниматься тяжелым физическим трудом, употреблять алкогольные напитки, переохлаждаться;</li>
            <li>накладывать какие-либо лекарственные препараты;</li>
            <li>чистку зубов проводить на следующий день.</li>
          </ul>
          <br>
          <p>
            В том случае, если боль не прекратилась рекомендуем принимать обезболивающий препарат «Кеторол», но не более 4 раз в сутки (до 40мг) в течение 1-5 дней. 
          </p>
          <p>
            При периодонтите и наличии гнойного процесса, необходимо принимать антибиотик в течение 5 дней.
          </p>          
          <p>
            Если из раны не прекращает поступать кровь или появилась сильная боль - срочно обратитесь к нам повторно!
          </p>          
          <br>
          <p>
            Более подробную консультацию Вы можете получить, обратившись к нам. 
          </p>
          <br><br>
        </div>
          <a-carousel autoplay arrows effect="fade">
            <template #prevArrow>
              <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                <left-circle-outlined />
              </div>
            </template>
            <template #nextArrow>
              <div class="custom-slick-arrow" style="right: 10px">
                <right-circle-outlined />
              </div>
            </template>
            <div><img class='ii' alt="" src="../assets/pa1.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa2.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa3.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa4.jpg" /></div>
          </a-carousel>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import Foot from '@/components/Foot.vue'

export default {
  name: 'OrthopedicsView',
  components: {
    Head,
    Foot,
    ACarousel: Carousel,
    LeftCircleOutlined, 
    RightCircleOutlined,
  }
}
</script>

<style scoped>

.wrap {
  max-width: 90%;
  margin: 60px auto;
  min-height: 500px;
}

.cont {
  margin: 60px auto;
  margin-bottom: 140px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

.left {
  margin-right: 30px;
}

p {
  width: 95%;
}

.ii {
  width: 100%;
  height: 450px;
  overflow: hidden;
  object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 450px;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 35px;
  height: 25px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

@media (max-width: 790px) {

.cont {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

}

</style>

