<template>
  <div>
    <Head />
    <div class="wrap">
      <div class="cont">
        <div>
          <h1>Ортодонтия</h1>
          <h3>Исправление прикуса</h3>
          <p>
            Правильный прикус является гарантией максимального контакта между зубами челюстей, что обеспечивает:
          </p>
          <ul>
            <li>нормальное развитие мышц лицевого скелета;</li>
            <li>сохранение места для постоянных зубов при ранней потере молочных;</li>
            <li>отсутствие нарушения дикции;</li>
            <li>исключение возникновения проблем с дыханием.</li>
          </ul>
          <br>
          <p>
            Методы исправления прикуса:
          </p>
          <ul>
            <li>при помощи брекетов;</li>
            <li>без брекетов.</li>
          </ul>
          <br>
          <p>
            Вида брекетов:
          </p>
          <ul>
            <li>вестибулярные – фиксируемые на видимой части зубов;</li>
            <li>лингвальные – невидимые глазу брекеты, устанавливаемые на небной поверхности зубов.</li>
          </ul>
          <br>
          <p>
            По материалу изготовления брекеты бывают:
          </p>
          <ul>
            <li>металлические – самый распространенный вариант, характеризующийся высокой прочностью системы;</li>
            <li>сапфировые – монокристаллический тип брекетов, абсолютно прозрачен;</li>
            <li>керамические – отличаются высокими эстетическими свойствами, приближенными к эмали натурального зуба;</li>
            <li>пластиковые – самый дешевый вариант, по прочности и внешнему виду значительно уступающий другим видам брекетов.</li>
          </ul>
          <br>
          <p>
            Период исправления прикуса при помощи брекетов составляет от 6 месяцев до 3 лет.
          </p>
          <br>
          <p>
            Для исправления прикуса без брекетов применяются:
          </p>
          <ul>
            <li>капы;</li>
            <li>ортодонтические пластины.</li>
          </ul>
          <br>
          <p>
            Капа – представляет собой ортодонтический аппарат специальной формы, изготовленный из гибкого прозрачного полимера. Капа производится на основе слепков пациента, не вызывает дискомфорта и плотно сидит на зубах. Для всего курса лечения, как правило, используют несколько кап, которые необходимо своевременно менять.
          </p>
          <p>
            Ортодонтические пластины – это многосоставной аппарат, состоящий из двух небных пластиковых пластин, соединенных винтами или пружинами и оснащенных крючками, которыми крепится на зубы. Выравнивание зубов с помощью данного аппарата осуществляется за счет регулирования пластин.
          </p>
          <br><br>
        </div>
          <a-carousel autoplay arrows effect="fade">
            <template #prevArrow>
              <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                <left-circle-outlined />
              </div>
            </template>
            <template #nextArrow>
              <div class="custom-slick-arrow" style="right: 10px">
                <right-circle-outlined />
              </div>
            </template>
            <div><img class='ii' alt="" src="../assets/pa1.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa2.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa3.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa4.jpg" /></div>
          </a-carousel>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import Foot from '@/components/Foot.vue'

export default {
  name: 'OrthodonticsView',
  components: {
    Head,
    Foot,
    ACarousel: Carousel,
    LeftCircleOutlined, 
    RightCircleOutlined,
  }
}
</script>

<style scoped>

.wrap {
  max-width: 90%;
  margin: 60px auto;
  min-height: 500px;
}

.cont {
  margin: 60px auto;
  margin-bottom: 140px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

p {
  width: 95%;
}

.ii {
  width: 100%;
  height: 450px;
  overflow: hidden;
  object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 450px;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 35px;
  height: 25px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

@media (max-width: 790px) {

.cont {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

}

</style>

