<template>
  <div>
    <Head />
    <div class="wrap">
      <h1>Цены</h1>
        <div><img class='ii' alt="" src="../assets/p1.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p2.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p3.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p4.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p5.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p6.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p7.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p8.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p9.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p10.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p11.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p12.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p13.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p14.jpg" /></div>
        <div><img class='ii' alt="" src="../assets/p15.jpg" /></div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'PricesView',
  components: {
    Head,
    Foot,
  }
}
</script>

<style scoped>
.wrap {
  max-width: 70%;
  margin: 60px auto;
}

.ii {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  border: 2px solid black;
}


@media (max-width: 790px) {

  

}

</style>

