<template>
  <div>
    <Head />
    <div class="wrap">
      <h1>Немешкин Сергей Александрович</h1>
      
        <div><img class='ii' alt="" src="../assets/nem1.png" /></div>
        <div><img class='ii' alt="" src="../assets/nem2.png" /></div>
        <div><img class='ii' alt="" src="../assets/nem3.png" /></div>
        <div><img class='ii' alt="" src="../assets/nem4.png" /></div>
     
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'BnuView',
  components: {
    Head,
    Foot,
  }
}
</script>

<style scoped>
.wrap {
  max-width: 90%;
  margin: 60px auto;
}

.ii {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}


@media (max-width: 790px) {

  

}

</style>

