<template>
    <div class="bhh">
        <div class="nn">
            <div class="log">
                <router-link class="lin" to="/">
                    <img src="../assets/logo.png" class="logo" alt="Ева дент">
                </router-link>
            </div>
            <router-link to="/nav"> 
                <button 
                class="bm" 
                v-if="small"
                >
                <svg xmlns="http://www.w3.org/2000/svg" 
                    width="50" 
                    height="50" 
                    fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                </svg>
                </button>
            </router-link> 
            <nav 
                v-if="!small"
            >
                <router-link to="/">Главная</router-link>
                <router-link to="/about">О клинике</router-link>
                <router-link to="/services">Лечение</router-link>
                <router-link to="/prices">Цены</router-link>
                <router-link to="/documentation">Документы</router-link>
                <router-link to="/contacts">Контакты</router-link>
            </nav>
        </div>
    </div>    
</template>

<script>
export default {
    name: 'Hbody',
    data: () => ({
        small: false
    }),
    created() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.onResize)
    },
    methods: {
        onResize() {
        this.small = window.innerWidth <= 790;
        }
    },
}
</script>

<style scoped>
.bhh {
    height: 91px;
    background: white;
    border-bottom: 0.3px solid #e5e5e5;
    position: sticky;
    top: 1.5em;
    min-height: 9em;
    z-index: 9;
    transition: all 0.4s ease;
    scroll-behavior: smooth;
    z-index: 19;
}

.nn {
    background: white;
    position: sticky;
    top: 4.5em;
    min-height: 5em;
    transition: all 0.4s ease;
    scroll-behavior: smooth;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
}

.logo {
    width: 10vw;
}

h1 {
    margin-bottom: 0;
    color: #0C435F;
}

h6 {
    margin-top: -10px;
    margin-bottom: 7px;
    color: #2EA3F2;
}

nav {
    transition: all 0.4s ease;
    scroll-behavior: smooth;
}

nav a {
    font-weight: bold;
    color: #00000099;
    margin-left: 25px;
    text-decoration: none;
    font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif;
    font-size: 21px;
}

nav a:hover {
    color: #2EA3F2;
}

nav a.router-link-exact-active {
    color: #2EA3F2;
}

@media (max-width: 790px) {

    .bhh {
        background: #FFF;
        top: 0;
        border-bottom: none;
        min-height: 1em;
    }

    .nn {
        background: #FFF;
        top: 0;
        min-height: 1em;
    }

    .log {
        margin-left: 10px;
        margin-top: 4px;
    }

    .logo {
        width: 120px;
    }

    h1 {
        color: #fff;
        margin-top: 3px;
    }

    h6 {
        margin-top: 0.1px;
        font-size: 19px;
    }

    .bm {
        border: none;
        background-color: #fff;
        color: #0C435F;
        margin-top: 8px;
    }

}

</style>