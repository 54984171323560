<template>
  <div>
    <Head />
    <div class="wrap">
      <h1>Санитарно-эпидемиологическое заключение</h1>
      
        <div><img class='ii' alt="" src="../assets/san1.png" /></div>
        <div><img class='ii' alt="" src="../assets/san2.png" /></div>
        <div><img class='ii' alt="" src="../assets/san3.png" /></div>
     
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import Foot from '@/components/Foot.vue'

export default {
  name: 'LicView',
  components: {
    Head,
    Foot,
  }
}
</script>

<style scoped>
.wrap {
  max-width: 90%;
  margin: 60px auto;
}

.ii {
  width: 100%;
  overflow: hidden;
  object-fit: cover;
}


@media (max-width: 790px) {

  

}

</style>

