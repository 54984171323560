<template>
    <div class="cam">
        <div data-aos="fade-up" data-aos-duration="3000">
            <div class="row row-cols-1 row-cols-md-2 g-2">
                <div class="card mb-3">
                    <router-link class="lin" to="/implantation">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src="../assets/gimp.jpg" class="img-fluid rounded-start" alt="">
                            </div>
                            <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Имплантация</h5>
                                <p class="card-text">
                                    Имплантация зубов – это наиболее оптимальный и самый современный способ замещения недостающих элементов зубного ряда.
                                </p>
                            </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="card mb-3">
                    <router-link class="lin" to="/hygiene">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src="../assets/gchis.jpg" class="img-fluid rounded-start" alt="">
                            </div>
                            <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Профессиональная чистка</h5>
                                <p class="card-text">
                                   Современная чистка зубов ультразвуком и пескоструйная обработка AirFlow.
                                </p>
                            </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="card mb-3">
                    <router-link class="lin" to="/veneers">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src="../assets/gvin.jpg" class="img-fluid rounded-start" alt="">
                            </div>
                            <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Виниры</h5>
                                <p class="card-text">
                                    Тонкие накладки под цвет зубов, изготовленные по индивидуальному заказу, которые покрывают ваш зуб, обеспечивая немедленное улучшение состояния зубов.
                                </p>
                            </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="card mb-3">
                    <router-link class="lin" to="/prosthesis">
                        <div class="row g-0">
                            <div class="col-md-4">
                                <img src="../assets/gpro.jpg" class="img-fluid rounded-start" alt="">
                            </div>
                            <div class="col-md-8">
                            <div class="card-body">
                                <h5 class="card-title">Зубные протезы</h5>
                                <p class="card-text">
                                    Съемные протезы для замены отсутствующих зубов. Предлагается в различных вариантах дизайна.
                                </p>
                            </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Card'
}
</script>

<style scoped>

.cam {
    margin-top: 35px;
}

.row {
    display: flex;
    justify-content: space-between;
}

.card {
    max-width: 496px; 
    max-height: 170px;
    padding: 0;
    transition: all 0.4s ease;
    cursor: pointer;
}

.card:hover {
  transform: scale(1.01);
  box-shadow: 2px 2px 10px 10px rgba(122, 122, 122, 0.7);
  transition: all 0.4s ease;
}

.col-md-4 {
    display: flex;
    justify-content: start;
}

img {
    width: 125px;
}

.card-title {
    text-align: start;
    color: #0C435F;
    font-weight: bold;
    margin-left: -30px;
}

.card-text {
    text-align: start;
    font-size: 15px;
    margin-left: -30px;
}

@media (max-width: 790px) {

    .cam {
        margin-top: 45px;
    }

    .row {
        display: flex;
        justify-content: center;
        margin-left: 0;
        margin-right: 0;
    }

    .card {
        width: 95%; 
        max-height: 170px;
        margin: auto;
    }

    .card-body {
        margin-top: -175px;
    }

    img {
        width: 132px;
    }

    .card-title {
        font-size: 15px;
        margin-left: 130px;
    }

    .card-text {
        margin-left: 130px;
        font-size: 13px;
    }

}

</style>