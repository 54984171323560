<template>
    <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                Как сделать зубы белее?
            </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    Доступно несколько вариантов. Мы предлагаем чистку зубов, отбеливание в кабинете и наборы для отбеливания на дом с отбеливающим гелем разной концентрации.                    
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                Как часто нужно проходить обследование?
            </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    Конечной целью является предотвращение кариеса, болезней и потери зубов. Ваш стоматолог может помочь вам, но только если вы запишетесь на прием. В зависимости от ваших индивидуальных обстоятельств, только вы и ваш стоматолог можете определить, как часто планировать визиты. Для большинства людей два раза в год является нормой.
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                Что такое заболевание десен?
            </button>
            </h2>
            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    Заболевание десен вызвано образованием зубного налета, который не удаляется. Это вызывает микробы, которые приводят к заболеваниям пародонта. Опухшие нежные красные десны и кровоточащие десны являются первым признаком заболевания. Единственный способ удалить зубной налет глубоко под деснами — профессиональная рутинная чистка.
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFour">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                Безопасен ли рентген зубов?
            </button>
            </h2>
            <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    Технологические достижения за последние годы привели к снижению уровня излучения. Типичная доза, как правило, чрезвычайно мала (около 0,150 мЗв для серии снимков с полным ртом, согласно веб-сайту Американской стоматологической ассоциации).
                </div>
            </div>
        </div>
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFive">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                Почему я должен использовать зубную нить?
            </button>
            </h2>
            <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                    Бактерии живут в зубном налете, вызванном частицами пищи. Чистка щеткой избавляет от некоторых, но чистка зубной нитью избавляет от бактерий, скрывающихся в крошечных пространствах, до которых зубная щетка не может добраться. Если не удалить налет, он затвердеет и превратится в зубной камень, который может вызвать заболевание пародонта.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Akkor'
}
</script>

<style scoped>
.accordion-button {
    color: #0C435F;
    background-color: #f9df81;
    font-size: 19px;
    font-weight: bold;
}

.accordion-button:hover {
    color: #f9df81;
    background-color: #0C435F;
}

.accordion-button:not(.collapsed) {
    color: #f9df81;
    background-color: #0C435F;
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-body {
    background-color: #0C435F;
    color: #fff;
    font-size: 17px;
    text-align: start;
}

@media (max-width: 790px) {

    .accordion {
        width: 100%;
    }

    .accordion-item {
        width: 100%;
    }

    .accordion-button {
        font-size: 14px;
        width: 100%;
    }

    .accordion-body {
        font-size: 13px;
    }

}

</style>