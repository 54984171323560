import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ServicesView from '../views/ServicesView.vue'
import PricesView from '../views/PricesView.vue'
import ContactsView from '../views/ContactsView.vue'
import NavView from '../views/NavView.vue'
import TherapyView from '../views/TherapyView.vue'
import OrthopedicsView from '../views/OrthopedicsView.vue'
import SurgeryView from '../views/SurgeryView.vue'
import ImplantationView from '../views/ImplantationView.vue'
import KidsView from '../views/KidsView.vue'
import OrthodonticsView from '../views/OrthodonticsView.vue'
import HygieneView from '../views/HygieneView.vue'
import LabView from '../views/LabView.vue'
import ProsthesisView from '../views/ProsthesisView.vue'
import ZirconiumView from '../views/ZirconiumView.vue'
import CermetView from '../views/CermetView.vue'
import VeneersView from '../views/VeneersView.vue'
import FillingsView from '../views/FillingsView.vue'
import RestorationView from '../views/RestorationView.vue'
import ArtrestorationView from '../views/ArtrestorationView.vue'
import ExtractionView from '../views/ExtractionView.vue'
import WisdomView from '../views/WisdomView.vue'
import ProtpreparationView from '../views/ProtpreparationView.vue'
import ImpreparationView from '../views/ImpreparationView.vue'
import UltrasoundView from '../views/UltrasoundView.vue'
import AirflowView from '../views/AirflowView.vue'
import DocumentationView from '../views/DocumentationView.vue'
import LicView from '../views/LicView.vue'
import SanView from '../views/SanView.vue'
import BnuView from '../views/BnuView.vue'
import KdnView from '../views/KdnView.vue'
import MrmView from '../views/MrmView.vue'
import NemView from '../views/NemView.vue'

import NotFoundComponent from '../views/NotFoundComponent.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },  
  {
    path: '/services',
    name: 'services',
    component: ServicesView
  },
  {
    path: '/prices',
    name: 'prices',
    component: PricesView
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: ContactsView
  },
  {
    path: '/nav',
    name: 'nav',
    component: NavView
  },
  {
    path: '/therapy',
    name: 'therapy',
    component: TherapyView
  },
  {
    path: '/orthopedics',
    name: 'orthopedics',
    component: OrthopedicsView
  },
  {
    path: '/surgery',
    name: 'surgery',
    component: SurgeryView
  },
  {
    path: '/implantation',
    name: 'implantation',
    component: ImplantationView
  },
  {
    path: '/kids',
    name: 'kids',
    component: KidsView
  },
  {
    path: '/orthodontics',
    name: 'orthodontics',
    component: OrthodonticsView
  },
  {
    path: '/hygiene',
    name: 'hygiene',
    component: HygieneView
  },
  {
    path: '/lab',
    name: 'lab',
    component: LabView
  },
  {
    path: '/prosthesis',
    name: 'prosthesis',
    component: ProsthesisView
  },
  {
    path: '/zirconium',
    name: 'zirconium',
    component: ZirconiumView
  },
  {
    path: '/cermet',
    name: 'cermet',
    component: CermetView
  },
  {
    path: '/veneers',
    name: 'veneers',
    component: VeneersView
  },
  {
    path: '/fillings',
    name: 'fillings',
    component: FillingsView
  },
  {
    path: '/restoration',
    name: 'restoration',
    component: RestorationView
  },
  {
    path: '/artrestoration',
    name: 'artrestoration',
    component: ArtrestorationView
  },
  {
    path: '/extraction',
    name: 'extraction',
    component: ExtractionView
  },
  {
    path: '/wisdom',
    name: 'wisdom',
    component: WisdomView
  },
  {
    path: '/protpreparation',
    name: 'protpreparation',
    component: ProtpreparationView
  },
  {
    path: '/impreparation',
    name: 'impreparation',
    component: ImpreparationView
  },
  {
    path: '/ultrasound',
    name: 'ultrasound',
    component: UltrasoundView
  },
  {
    path: '/airflow',
    name: 'airflow',
    component: AirflowView
  },
  {
    path: '/documentation',
    name: 'documentation',
    component: DocumentationView
  },
  {
    path: '/lic',
    name: 'lic',
    component: LicView
  },
  {
    path: '/san',
    name: 'san',
    component: SanView
  },
  {
    path: '/bnu',
    name: 'bnu',
    component: BnuView
  },
  {
    path: '/kdn',
    name: 'kdn',
    component: KdnView
  },
  {
    path: '/mrm',
    name: 'mrm',
    component: MrmView
  },
  {
    path: '/nem',
    name: 'nem',
    component: NemView
  },
  
  
  {
    path: '/:pathMatch(.*)*', 
    name: 'notfound',
    component: NotFoundComponent
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
    return { 
      top: 0, 
      behavior: 'smooth',
    }
  },
})

export default router
