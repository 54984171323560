<template>
  <div>
    <Head />
    <div class="wrap">
      <div class="cont">
        <div class="left">
          <h1>Пескоструйная обработка AirFlow</h1>
          <p>
            Очищенные зубы имеют шероховатую поверхность. Чтобы замедлить налипание пищи и бактерий, применяют полировку.
          </p>
          <p>
            Дословно название Air Flow переводится как «поток воздуха».
          </p>
          <p>
            Аппаратная обработка методом AirFlow напоминает пескоструйную технологию, только вместо песка используется стоматологическая абразивная смесь, состоящая из соды и поваренной соли. В нее также могут добавляться вещества с бактерицидным и освежающим эффектом.
          </p>
          <p>
            Иногда этот процесс называют отбеливание зубов Air Flow, хотя отбеливанием на самом деле он не является. Эмаль становится светлее только потому, что с нее удаляется темный налет, а затем проводится полировка специальными резинками, щеточками и пастами. Поэтому зубы приобретают свой первозданный свет.
          </p>
          <br>
          <p>
            Противопоказания:
          </p>
          <ul>
            <li>
              слишком тонкая эмаль, что вызывает повышенную чувствительность. В этом случае применяют порошок, содержащий анестетики, это позволяет избежать болевых ощущений. Если же эмаль настолько тонка, что может быть повреждена при проведении манипуляции, стоматолог процедуру отменит. Это случается крайне редко, так как методика является очень щадящей, и обычно не приводит к нарушению естественных структур зубов;
            </li>
            <li>
              аллергия на любой из компонентов абразивной смеси. Если пациент склонен к аллергиям, следует выбирать состав, который не содержит ароматизаторов и анестетиков. Стандартный микс из соды и поваренной соли гипоаллергенен, и противопоказаний не имеет;
            </li>
            <li>
              в случае астмы пыль абразивной смеси может спровоцировать приступ. Поэтому людям, страдающим этим заболеванием, следует проводить чистку с особой осторожностью, чтобы предотвратить попадание абразива в бронхи;
            </li>
            <li>
              если назначена строгая бессолевая диета, обработку следует отложить до ее окончания, так как абразивная смесь содержит соль.
            </li>
          </ul>
          <br>
          <p>
            Более подробную консультацию Вы можете получить, обратившись к нам. 
          </p>
          <br><br>
        </div>
          <a-carousel autoplay arrows effect="fade">
            <template #prevArrow>
              <div class="custom-slick-arrow" style="left: 10px; z-index: 1">
                <left-circle-outlined />
              </div>
            </template>
            <template #nextArrow>
              <div class="custom-slick-arrow" style="right: 10px">
                <right-circle-outlined />
              </div>
            </template>
            <div><img class='ii' alt="" src="../assets/pa1.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa2.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa3.jpg" /></div>
            <div><img class='ii' alt="" src="../assets/pa4.jpg" /></div>
          </a-carousel>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '@/components/Head.vue'
import { Carousel } from 'ant-design-vue';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue';
import Foot from '@/components/Foot.vue'

export default {
  name: 'OrthopedicsView',
  components: {
    Head,
    Foot,
    ACarousel: Carousel,
    LeftCircleOutlined, 
    RightCircleOutlined,
  }
}
</script>

<style scoped>

.wrap {
  max-width: 90%;
  margin: 60px auto;
  min-height: 500px;
}

.cont {
  margin: 60px auto;
  margin-bottom: 140px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(520px, 1fr));
}

.left {
  margin-right: 30px;
}

p {
  width: 95%;
}

.ii {
  width: 100%;
  height: 450px;
  overflow: hidden;
  object-fit: cover;
}

.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 450px;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}

.ant-carousel :deep(.slick-arrow.custom-slick-arrow) {
  width: 35px;
  height: 25px;
  font-size: 35px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
  z-index: 1;
}

.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}

.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 0.5;
}

@media (max-width: 790px) {

.cont {
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

}

</style>

