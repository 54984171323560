<template>
    <div class="nn">
        <div class="hh">
            <a @click="$router.go(-1)">             
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" 
                        width="40" 
                        height="40" 
                        fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                        <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
                    </svg>
                </button>
            </a>
        </div>
        <br>
        <nav>
            <ul>
                <li><router-link to="/">Главная</router-link></li>
                <li><router-link to="/about">О клинике</router-link></li>
                <li><router-link to="/services">Лечение</router-link></li>
                <li><router-link to="/prices">Цены</router-link></li>
                <router-link to="/documentation">Документы</router-link>
                <li><router-link to="/contacts">Контакты</router-link></li>
            </ul>
        </nav>
        <div class="foo">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-telephone-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
            </svg>
            &nbsp; &nbsp; 
            <a class="tel" href="tel:+79674700044">
                <h5 class="ht">
                    36-00-44
                </h5>
            </a>   
        </div>
        <div class="foo">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
                <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
            </svg>
            &nbsp; &nbsp; 
            <router-link 
                class="rl"
                to="/contacts">
                <h5 class="ha">
                    д. Чергаши, ул. Пригородная, 30 
                </h5>
            </router-link>
        </div>
        <div class="foo">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-pencil-square" viewBox="0 0 16 16">
                <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
            </svg>
            &nbsp; &nbsp; 
            <button 
                type="button" 
                data-bs-toggle="modal" 
                data-bs-target="#exampleModal"
                class="bhz"
            >
                <h5 class="hz">
                    Записаться на прием
                </h5>
            </button>
        </div>
        <br><br>
    </div>
    <Modal />
</template>

<script>
import Modal from '@/components/Modal.vue'

export default {
    name: 'NavView',
    components: {
        Modal,
    },
}
</script>

<style scoped>
.hh {
    display: flex;
    justify-content: end;
}

button {
    margin: 23px 24px;
    width: 50px;
    height: 50px;
    background: none;
    border: none;
    color: #fff;
}

.nn {
    background: #0C435F;
    min-height: 100vh;
}

.nav {
       
}

nav a {
    display: block;
    text-align: end;
    margin-right: 70px;
    margin-bottom: 30px;
    font-size: 21px;
    color: #fff;
    text-decoration: none;
}

nav a:hover {
    color: #2EA3F2;
}

nav a.router-link-exact-active {
    color: #2EA3F2;
}

ul {
    margin-left: 0; 
    padding-left: 0; 
    margin-bottom: 0;
}

li {
    list-style-type: none; 
}

.foo {
    display: flex;
    justify-content: end;
    margin-right: 70px;
    margin-bottom: 20px;
}

h5 {
    color: #fff;
    font-size: 16px;
    transition: all 0.4s ease-in-out;
    margin-bottom: 0;
}

h5:hover {
    color: rgb(186 196 207);
    transition: all 0.4s ease-in-out;
}

.bhz {
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 0;
    width: 170px;
    height: 18px;
}

.hz {
    width: 170px;
}

</style>